<template>
  <header class="header">
    <router-link to="/" class="header__logo">
      egrnservice
    </router-link>
    <ul class="header__contacts">
      <li class="header__mail">
        <span class="advantages-line__item">Email: </span>
        <a class="header__contact" href="mailto:support@egrnservice.ru">
          support@egrnservice.ru
        </a>
      </li>
      <li class="header__phone">
        <span class="advantages-line__item">Поддержка:</span>
        <a class="header__contact" href="tel:+74952410152">
          +7 495 241 01 52
        </a>
      </li>
      <li class="header__phone">
        <span class="advantages-line__item">Консультация юриста:</span>
        <a class="header__contact" href="tel:+74994907063">
          +7 499 490 70 63
        </a>
      </li>
      <li>
        <router-link to="/cart" class="header__cart">
          <span class="cart-count">{{ count }}</span>
        </router-link>
      </li>
    </ul>
  </header>
</template>

<script>
import './header.scss';

export default {
  name: 'Header',
  computed: {
    count() {
      return this.$store.state.cartData.length;
    }
  }
};
</script>
