<template>
  <div
    class="title"
    :class="{
      title_small: small
    }"
  >
    <h2 v-if="$slots.default" class="title__main">
      <slot></slot>
    </h2>
    <p v-if="$slots.subtitle" class="title__sub">
      <slot name="subtitle"></slot>
    </p>
  </div>
</template>

<script>
import './title.scss';

export default {
  name: 'Title',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>
