<template>
  <div class="login-page">
    <div class="login-page__bg-wrapper login-page__top">
      <span class="login-page__bg"></span>
      <div class="login-page__bg-content">
        <Header />
        <form class="login-page__form" @submit.prevent="validate">
          <Title>Войти в систему</Title>
          <Field
            v-model="form.email"
            class="form__input-field"
            title="E-mail *"
            type="email"
            name="email"
            :error="formErrors.email"
          />
          <Field
            v-model="form.password"
            class="form__input-field"
            title="Пароль *"
            :error="formErrors.password"
          />
          <Button type="submit">
            Заказать
          </Button>
        </form>
      </div>
    </div>
    <Footer />
    <Loader v-if="false" />
  </div>
</template>

<script>
import Header from '@/components/uniq/header/Header';
import Field from '@/components/gui/field/Field';
import Button from '@/components/gui/button/Button';
import Title from '@/components/gui/title/Title';
import Loader from '@/components/views/main/loader/Loader.vue';

import Validation from '@/ext/validation/ValidationWithFocus';
import login from '@/api/login';

import './login-page.scss';

export default {
  name: 'Login',
  components: {
    Header,
    Field,
    Button,
    Title,
    Loader,
    Footer: () => import('@/components/uniq/footer/Footer')
  },
  data() {
    return {
      formRules: {
        email: [Validation.REQUIRED, Validation.EMAIL],
        password: [Validation.REQUIRED]
      },
      form: {
        email: '',
        password: ''
      },
      formErrors: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    validate(submit = true) {
      // this.formIsSubmit = true;
      debugger;
      const selector = submit ? '.field_error input' : null;
      const validation = new Validation(
        selector,
        this.form,
        this.formRules
      ).make();

      this.formErrors = validation.errors;

      submit && validation.isValid && this.submit();
    },
    async submit() {


      const { Success, objects, found, found_all } = await login({
        email: this.email,
        password: this.password
      });

      console.log(Success, objects, found, found_all);
    }
  }
};
</script>
