<template>
  <div class="loader-wrap" :class="{ 'local-size': !full }">
    <div class="loader"></div>
  </div>
</template>

<script>
import './loader.scss';

export default {
  name: 'Loader',
  props: {
    full: {
      default: true,
      type: Boolean
    }
  }
};
</script>
